var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(" " + _vm._s(_vm.pageheading) + " ")]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _c(
          "div",
          {
            staticStyle: { overflow: "hidden" },
            attrs: { id: "body-content-area", submit: _vm.validateAndSubmit },
          },
          [
            _vm.isLoading
              ? _c("div", [_c("Loading")], 1)
              : _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: { "padding-top": "20px" },
                      attrs: { id: "buttongroup" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "field is-grouped",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-end!important",
                            gap: "1rem",
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button is-accent has-text-white",
                              attrs: {
                                disabled: !_vm.$hasPermissions(
                                  _vm.clientSession,
                                  ["ANNUAL_QUESTIONNAIRE"],
                                  2
                                ),
                                type: "submit",
                              },
                              on: { click: _vm.validateAndSubmit },
                            },
                            [_vm._v("Save")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "button is-light",
                              on: {
                                click: function ($event) {
                                  _vm.resetForm()
                                  _vm.$router.go(-1)
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { attrs: { id: "pagelayout" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "columns",
                        staticStyle: { "flex-wrap": "wrap" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "column",
                            staticStyle: { "min-width": "100%" },
                          },
                          [
                            _vm.errors && _vm.errors.length
                              ? _c(
                                  "div",
                                  _vm._l(_vm.errors, function (error) {
                                    return _c(
                                      "div",
                                      {
                                        key: error,
                                        staticStyle: { color: "red" },
                                      },
                                      [_vm._v(_vm._s(error))]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "column",
                            staticStyle: { "max-width": "50%" },
                          },
                          [
                            _vm._m(0),
                            _c("div", { staticClass: "block" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "flex-container",
                                  staticStyle: { "margin-bottom": "1rem" },
                                },
                                [
                                  _c("div", { staticClass: "flex-field" }, [
                                    _vm._m(1),
                                    _c("div", { staticClass: "field" }, [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.input.defaultMessage,
                                            expression: "input.defaultMessage",
                                          },
                                        ],
                                        attrs: { rows: "5", cols: "50" },
                                        domProps: {
                                          value: _vm.input.defaultMessage,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.input,
                                              "defaultMessage",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "flex-container" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex-field" },
                                  [
                                    _vm._m(2),
                                    _c("kendo-datepicker", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      attrs: {
                                        id: "dueDate",
                                        name: "due date",
                                        format: "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: _vm.input.defaultDueDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.input,
                                            "defaultDueDate",
                                            $$v
                                          )
                                        },
                                        expression: "input.defaultDueDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex-field" },
                                  [
                                    _vm._m(3),
                                    _c("kendo-datepicker", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      attrs: {
                                        id: "asOfDate",
                                        name: "info date",
                                        format: "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: _vm.input.defaultAsOfDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.input,
                                            "defaultAsOfDate",
                                            $$v
                                          )
                                        },
                                        expression: "input.defaultAsOfDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "column",
                            staticStyle: { "max-width": "50%" },
                          },
                          [
                            _vm._m(4),
                            _vm._l(
                              _vm.notificationsTable,
                              function (reminder, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "flex-container flex-left",
                                    staticStyle: {
                                      "margin-top": "1rem",
                                      width: "100%",
                                      gap: "0.5rem !important",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex-field",
                                        staticStyle: { "max-width": "150px" },
                                      },
                                      [
                                        _vm._m(5, true),
                                        _c("kendo-datepicker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            id: "reminderDate",
                                            format: "yyyy-MM-dd",
                                          },
                                          model: {
                                            value: reminder.remindAt,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                reminder,
                                                "remindAt",
                                                $$v
                                              )
                                            },
                                            expression: "reminder.remindAt",
                                          },
                                        }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: reminder.remindAt,
                                              expression: "reminder.remindAt",
                                            },
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: false,
                                              expression: "false",
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          attrs: { name: "reminder date " + i },
                                          domProps: {
                                            value: reminder.remindAt,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                reminder,
                                                "remindAt",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-field flex-left flex-container",
                                        staticStyle: {
                                          width:
                                            "calc(100% - 0.5rem - 200px) !important",
                                          position: "relative",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex-field",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _vm._m(6, true),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "select",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          reminder.emailTemplateId,
                                                        expression:
                                                          "reminder.emailTemplateId",
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      name:
                                                        "email template " + i,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          reminder,
                                                          "emailTemplateId",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.emailTemplates.filter(
                                                      function (x) {
                                                        return (
                                                          _vm.QuestionnaireType
                                                            .id ===
                                                          x.emailTemplateTypeCategoryId
                                                        )
                                                      }
                                                    ),
                                                    function (opt) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: opt.id,
                                                          domProps: {
                                                            value: opt.id,
                                                            selected:
                                                              parseInt(
                                                                opt.id
                                                              ) ===
                                                              parseInt(
                                                                reminder.emailTemplateId
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(opt.name) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex-field",
                                        staticStyle: {
                                          padding: "0",
                                          "padding-top": ".5rem",
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "label is-size-7" },
                                          [_vm._v("   ")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "button is-light",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeReminder(
                                                  reminder
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("-")]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  display: "flex",
                                  "justify-content": "flex-end",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-light",
                                    staticStyle: {
                                      "margin-left": "auto",
                                      "margin-top": "0.5rem",
                                    },
                                    on: { click: _vm.addReminder },
                                  },
                                  [_vm._v("Add reminder")]
                                ),
                              ]
                            ),
                            _vm._m(7),
                            _c(
                              "div",
                              {
                                staticClass: "select",
                                staticStyle: { width: "100%" },
                              },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.input.defaultSentEmailTemplate,
                                        expression:
                                          "input.defaultSentEmailTemplate",
                                      },
                                    ],
                                    staticStyle: { width: "100%" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.input,
                                          "defaultSentEmailTemplate",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.emailTemplates.filter(function (x) {
                                      return (
                                        _vm.QuestionnaireType.id ===
                                        x.emailTemplateTypeCategoryId
                                      )
                                    }),
                                    function (opt) {
                                      return _c(
                                        "option",
                                        {
                                          key: opt.id,
                                          domProps: {
                                            value: opt.id,
                                            selected:
                                              parseInt(opt.id) ===
                                              parseInt(
                                                _vm.input
                                                  .defaultSentEmailTemplate
                                              ),
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(opt.name) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Default Properties"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label", attrs: { for: "" } }, [
      _vm._v(" Default Message "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Default Due Date "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Default Info 'As Of' Date "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Default Reminder Schedule"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Reminder Date/Time "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Email Template "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v('Default On "Send" Notification'),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }